
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class AboutMyManagement extends Vue {
        // Data function
        public data() {
            return {
                agileQuiz:
                    require("@/assets/quizzes/management-agile-software-development-quiz.json"),

                analyticsIconPathAndName:
                    require("@/assets/images/icon_analytics.png"),

                bridgeImagePathAndName:
                    require("@/assets/images/bridge.png"),

                individualIconPathAndName:
                    require("@/assets/images/icon_individual_contributor.png"),

                leaderImagePathAndName:
                    require("@/assets/images/leader_vs_boss.png"),

                managerIconPathAndName:
                    require("@/assets/images/icon_manager.png"),

                mapIconPathAndName:
                    require("@/assets/images/icon_map.png"),

                roadmapAgilePathAndName:
                    require("@/assets/images/roadmap_agile.png"),

                roadmapExecutiveFacingPathAndName:
                    require("@/assets/images/roadmap_executive_facing.png"),

                roadmapProductLaunchPathAndName:
                    require("@/assets/images/roadmap_product_launch.png"),

                roadmapReleasePlanPathAndName:
                    require("@/assets/images/roadmap_release_plan.png"),

                roadmapThemesPathAndName:
                    require("@/assets/images/roadmap_themes.png"),

                scrumQuiz:
                    require("@/assets/quizzes/management-scrum-terminology-quiz.json"),

                userStoryMapping:
                    require("@/assets/images/user_story_mapping.png"),
            };
        }
    }
